import CLFilterDTO from '../DTO/courseLeadersFilterDTO';
import {FILTERS_ARGS} from '../courseLeadersConst';

export class CLFiltersModel {

  constructor(filters = {}) {
    this.filters = new CLFilterDTO(filters);
  }

  getApiData() {
    return {
      [FILTERS_ARGS.SEARCH]: (this.filters.search && this.filters.search.length) ? this.filters.search : undefined,
      [FILTERS_ARGS.IS_ARCHIVED]: this.filters.isArchived
    };
  }
  
}
