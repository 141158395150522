<template lang="pug">
  div.filters
    filters-list(:filters="filters" @filterChanged="startFiltering")
</template>

<script>
import { CLFiltersModel } from "../../core/models/courseLeadersFiltersModel";

export default {
  data: () => ({
    model: {},
    filters: {
      search: '',
      isArchived: null
    }
  }),

  created() {
    this.initFilters()
  },

  methods: {
    initFilters() {
      this.model = new CLFiltersModel(this.$route.query);
      this.setFilters();
    },

    async startFiltering() {
      this.setFiltersToModel();
      await this.setQuery();
    },

    setFiltersToModel() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.model.filters[filter] = this.filters[filter]
        }
      }
    },

    async setQuery(e) {
      await this.$router.replace({query: {...this.$route.query, ...this.model.getApiData()}});
      this.$emit('filterChanged');
    },

    setFilters() {
      for (let filter in this.filters) {
        if (filter in this.model.filters) {
          this.filters[filter] = this.model.filters[filter]
        }
      }
    }
  },

  components: {
    filtersList: () => import('./courseLeadersFiltersList.vue')
  }
}
</script>

<style lang="scss" scoped>
.filters {
  width: 100%;
}
</style>
